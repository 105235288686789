<template>
  <section class="modify-event">
    <h2 class="title">修改事件</h2>
    <el-form label-width="80px" :model="form" v-loading="loading">
      <el-form-item label="事件名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
<!--      <el-form-item label="创建日期">-->
<!--        <el-date-picker-->
<!--          v-model="form.createDate"-->
<!--          type="datetime"-->
<!--          value-format="yyyy-MM-dd HH:mm:ss">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
      <el-form-item label="起始时间">
        <el-date-picker
          v-model="form.startDate"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="form.endDate"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="核心词">
        <el-input type="textarea" :rows="5" v-model="form.keyword" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="同现词">
        <el-input type="textarea" :rows="5" v-model="form.cooccurrenceWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item label="排除词">
        <el-input type="textarea" :rows="5" v-model="form.excludeWord" placeholder="词与词之间请用、隔开"></el-input>
      </el-form-item>
      <el-form-item class="btn-wrap">
        <el-button @click="goBack">返回</el-button>
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { showEventEvolution, updateEventEvolution } from '@/api/eventEvolution'
export default {
  data () {
    return {
      eventId: '',
      loading: false,
      form: {
        id: '',
        name: '',
        createDate: '',
        startDate: '',
        endDate: '',
        keyword: '',
        cooccurrenceWord: '',
        excludeWord: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      this.$router.back()
      this.updateEventEvolutionById()
    },
    goBack () {
      this.$router.back()
    },
    async showEventEvolutionid () {
      this.loading = true
      this.eventId = this.$route.params.id
      console.log(this.eventId)
      try {
        // eslint-disable-next-line standard/object-curly-even-spacing
        const res = await showEventEvolution({ id: this.eventId })
        console.log(res)
        this.form = res.data
        this.loading = false
        console.log(res.msg)
      } catch (error) {
        console.log(error)
      }
    },
    async updateEventEvolutionById () {
      this.eventId = this.$route.params.id
      this.form.id = this.eventId
      console.log(this.eventId)
      try {
        const res = await updateEventEvolution(this.form)
        console.log(res)
        if (res.code === 0) {
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    this.showEventEvolutionid()
  }
}
</script>

<style lang="scss">
.modify-event {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(53,64,82,0.06);
  border-radius: 10px;
  padding: 40px 30px;
  .title {
    font-size: 18px;
    color: #333333;
    margin: 0;
  }
  .el-form {
    margin-top: 30px;
    .el-input {
      width: 480px;
    }
    .btn-wrap {
      margin-top: 70px;
    }
  }
}
</style>
